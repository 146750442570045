<template>
  <div id="app">
    <b-overlay :show="headerchanging" :opacity="0.85" rounded="lg" no-wrap />
    <!-- <div :class="isLoaded ? '' : 'maincontent'"> -->
    <Navbar v-if="showNavigation" id="header" />
    <router-view id="main" />
    <GoToTopButton v-if="showNavigation" />
    <Footer v-if="showNavigation" />
    <!-- <QRCodeFooter v-if="!showNavigation" /> -->
    <QRCodeFooter v-if="showQRCodeFooter" />
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import Navbar from '@/layout/components/Navbar'
import Footer from '@/layout/components/Footer'
import QRCodeFooter from '@/layout/components/QRCodeFooter'
import GoToTopButton from '@/components/go-to-top-button'

export default {
  name: 'App',
  components: {
    BOverlay,
    Navbar,
    Footer,
    QRCodeFooter,
    GoToTopButton
  },
  data() {
    return {
      headerchanging: false,
      last_generated_time: 0
      // showNavigation: false,
      // showQRCodeFooter: false
    }
  },
  computed: {
    showNavigation() {
      return this.$route.name !== 'fps-qrcode-generator' && this.$route.name !== 'fps-qrcode-generator-result' && this.$route.name !== 'signup-confirmation'
    },
    showQRCodeFooter() {
      return this.$route.name === 'fps-qrcode-generator' || this.$route.name === 'fps-qrcode-generator-result'
    }
  },
  watch: {
    async $route(to, from) {
      if (this.$route.params.lang) {
        console.log('watch:Set language to', this.$route.params.lang)
        // this.$root.$i18n.locale = this.$route.params.lang
        this.setI18n(this.$route.params.lang)
      } else {
        console.log('watch:Set language as lange is null : ', this.$route.params.lang)
        this.setI18n(null)
      }
    }
  },
  /*
  mounted() {
    this.setI18n()
  },
  */
  methods: {
    countdown() {
      this.last_generated_time = Date.now()
    },
    setI18n(aLangCode) {
      //  This function sets the i18n language according to browser current language (navigator.language)
      //  Convert navigator.language to language code we want.
      let lang_code = 'en-US'
      if (aLangCode != null) {
        lang_code = aLangCode
      }
      switch (lang_code.toLowerCase()) {
        //  Hong Kong / Macau / Taiwan user - Traditional Chinese.
        case 'zh-hk':
        case 'zh-mo':
        case 'zh-tw':
          lang_code = 'zh-Hant'
          break

        //  China / Singapore - Simplified Chinese.
        case 'zh-cn':
        case 'zh-sg':
          lang_code = 'zh-Hans'
          break

        //  If not these 5 languages - fallback to use English.
        default:
          lang_code = 'en-US'
          break
      }
      console.log('methods:Set language to', lang_code)
      this.$root.$i18n.locale = lang_code
    }
  }
}
</script>

<style scoped>
.maincontent {
  height: 100%;
}
#main {
  position: relative;
  margin: 0px;
}
</style>

export function convertLangCode(lang_code) {
  switch (lang_code.toLowerCase()) {
    case 'zh-hk':
    case 'zh-mo':
    case 'zh-tw':
      return 'zh-hk'
    case 'zh-cn':
    case 'zh-sg':
      return 'zh-cn'
    case 'en':
    case 'en-au':
    case 'en-bz':
    case 'en-ca':
    case 'en-cb':
    case 'en-gb':
    case 'en-ie':
    case 'en-jm':
    case 'en-nz':
    case 'en-ph':
    case 'en-tt':
    case 'en-us':
    case 'en-za':
    case 'zn-zw':
      return 'en-us'
    default:
      return 'en-us'
  }
}

// export function isInIsoLangList() {
//     const isoLangList = [
//         'en', 'en-au', 'en-bz', 'en-ca', 'en-cb', 'en-gb', 'en-ie', 'en-jm', 'en-nz', 'en-ph', 'en-tt', 'en-us', 'en-za', 'zn-zw',
//         'zh-hk', 'zh-mo', 'zh-tw',
//         'zh-cn', 'zh-sg'
//       ]
// }

export function convertCategoryKey(content_key) {
  switch (content_key) {
    case 'pi_opt1':
      return 'M02d-15b-01'
    default:
      return 'M02d-15b-01'
  }
}

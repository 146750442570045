import Vue from 'vue'
import Router from 'vue-router'
import { convertLangCode } from '@/utils/language-util'
import store from '../store'
import _ from 'lodash'

Vue.use(Router)

//  Determine users' preference param for redirect.
// const prefParam = navigator.language
/*
const langRedirect = (to) => ({
  name: to.meta.name,
  params: { ...to.params },
  hash: to.hash
})
*/

// export const constantRoutes = [
const originalRoutes = [
  {
    name: 'home',
    path: '/:lang',
    component: () => import('@/views/home/index')
  },
  {
    name: 'portal-generator',
    path: '/:lang/portal-generator',
    component: () => import('@/views/portal-generator/index')
  },
  {
    name: 'api-portal',
    path: '/:lang/api-portal',
    component: () => import('@/views/api-portal/index')
  },
  {
    name: 'api-management',
    path: '/:lang/api-management',
    component: () => import('@/views/api-management/index')
  },
  {
    name: 'api-monetization',
    path: '/:lang/api-monetization',
    component: () => import('@/views/api-monetization/index')
  },
  // {
  //   name: 'serverless-api',
  //   path: '/:lang/serverless-api',
  //   component: () => import('@/views/serverless-api/index')
  // },
  {
    name: 'apiops-cicd',
    path: '/:lang/apiops-cicd',
    component: () => import('@/views/apiops-cicd/index')
  },
  {
    name: 'api-hub-community',
    path: '/:lang/api-hub-community',
    component: () => import('@/views/api-hub-community/index')
  },
  {
    name: 'trust-certification',
    path: '/:lang/trust-certification',
    component: () => import('@/views/trust-certification/index')
  },
  {
    name: 'api-hub-community',
    path: '/:lang/api-hub-community',
    component: () => import('@/views/api-hub-community/index')
  },
  {
    name: 'api-transformations',
    path: '/:lang/api-transformations',
    component: () => import('@/views/api-transformations/index')
  },
  {
    name: 'enterprise-api-hub',
    path: '/:lang/enterprise-api-hub',
    component: () => import('@/views/enterprise-api-hub/index')
  },
  {
    name: 'academy',
    path: '/:lang/academy',
    component: () => import('@/views/academy/index')
  },
  // {
  //   name: 'integration-aws-api-gateway',
  //   path: '/:lang/integration-aws-api-gateway',
  //   component: () => import('@/views/integration-aws-api-gateway/index')
  // },
  {
    name: 'use-case',
    path: '/:lang/use-cases',
    component: () => import('@/views/use-cases/index')
  },
  {
    name: 'use-case-detail',
    path: '/:lang/use-cases/:id',
    component: () => import('@/views/use-cases/details')
  },
  {
    name: 'pricing',
    path: '/:lang/pricing',
    component: () => import('@/views/pricing/index')
  },
  {
    name: 'fps-qrcode-generator',
    path: '/:lang/fps-qrcode-generator',
    component: () => import('@/views/qr-code-generator/index')
  },
  {
    name: 'fps-qrcode-generator-result',
    path: '/:lang/fps-qrcode-generator-result',
    props: true,
    component: () => import('@/views/qr-code-generator/generate')
  },
  {
    name: 'academy',
    path: '/:lang/academy',
    component: () => import('@/views/academy/index')
  },
  {
    name: 'api-provider',
    path: '/:lang/api-provider',
    component: () => import('@/views/api-provider/index')
  },
  {
    name: 'api-developer',
    path: '/:lang/api-developer',
    component: () => import('@/views/api-developer/index')
  },
  {
    name: 'tech-startup',
    path: '/:lang/tech-startup',
    component: () => import('@/views/tech-startup/index')
  },
  {
    name: 'about',
    path: '/:lang/about',
    component: () => import('@/views/about/index')
  },
  {
    name: 'contact',
    path: '/:lang/contact',
    component: () => import('@/views/contact/index')
  },
  {
    name: 'press',
    path: '/:lang/press',
    component: () => import('@/views/press/index')
  },
  {
    name: 'coupons-detail',
    path: '/:lang/coupons/:id',
    component: () => import('@/views/coupons/details')
  },
  {
    name: 'api-portal-themes',
    path: '/:lang/themes',
    component: () => import('@/views/api-portal-themes/index')
  },
  {
    name: 'book-demo',
    path: '/:lang/book-demo',
    component: () => import('@/views/book-demo/index')
  },
  /*
  {
    name: 'demo-ftc',
    path: '/:lang/demo/ftc',
    props: true,
    component: () => import('@/views/demo/ftc/index')
  },
  */
  {
    path: '/:lang/community-updates',
    name: 'community-updates-overview',
    component: () => import('@/views/community-updates/overview'),
    beforeEnter: async(to, from, next) => {
      console.log('entered Community Updates router')
      if (store.getters.events.length === 0) {
        await store.dispatch('events/getEventList').then(() => {
          next()
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/:lang/community-updates/:eventTitle',
    name: 'community-updates-details',
    component: () => import('@/views/community-updates/details')
  },
  // {
  //   path: '/:lang/community-updates',
  //   // component: Layout,
  //   children: [
  //     {
  //       name: 'community-updates-overview',
  //       path: '',
  //       component: () => import('@/views/community-updates/overview'),
  //       meta: { title: 'Community Updates', id: 'M01b' },
  //       beforeEnter: async(to, from, next) => {
  //         console.log('entered Community Updates router')
  //         if (store.getters.events.length === 0) {
  //           await store.dispatch('events/getEventList').then(() => {
  //             next()
  //           })
  //         } else {
  //           next()
  //         }
  //       }
  //     },
  //     {
  //       name: 'community-updates-details',
  //       path: ':eventTitle',
  //       component: () => import('@/views/community-updates/details'),
  //       meta: {
  //         title: 'Community Updates',
  //         id: 'M01c',
  //         addEventTitle: true
  //       }
  //     }
  //   ]
  // },
  {
    path: '/:lang/member',
    // component: Layout,
    children: [
      {
        name: 'community-updates-protected-content',
        path: 'community-updates/:eventTitle/resources',
        component: () => import('@/views/community-updates/event-template/protected-content.vue'),
        beforeEnter: async(to, from, next) => {
          console.log('next', to)
          window.open(`https://hub.openapihub.com${to.path}`, '_blank')
        }
      }
    ]
  },
  {
    name: 'page-not-found',
    path: '*',
    redirect: { name: 'home' }
  },
  {
    name: 'signup-confirmation',
    path: '/:lang/signup-confirmation',
    component: () => import('@/views/signup-confirmation/index')
  }
]

const originalWithRedirectRoutes = _.cloneDeep(originalRoutes)
originalRoutes.forEach(route => {
  if (route.path === '*') {
    return
  }
  // console.log(`route.name : ${route.name}, route.path : ${route.path}, `)
  // route.children.forEach(c => {
  /*
  originalWithRedirectRoutes.splice(
    originalWithRedirectRoutes.findIndex(r => r.path === route.path),
    0,
    {
      // path: `${route.path.split('/:lang')[1]}${route.path || route.name === 'home' ? `/${route.path}` : ''}`,
      path: `${route.path.split('/:lang')[1]}`,
      redirect: to => langRedirect(to),
      meta: {
        name: route.name
      }
    }
  )
  */
  // console.log(`Route: ${route.path.split('/:lang')[1]}`)
  // console.log(originalWithRedirectRoutes)
  // })
})

export const constantRoutes = originalWithRedirectRoutes

const createRouter = () => new Router({
  mode: 'history', // require service support
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { y: 0 }
    }
  },
  routes: constantRoutes
})

const router = createRouter()
router.beforeEach((to, from, next) => {
  // if (to.name !== 'portal-generator-hk' && to.name !== 'portal-generator-cn') {
  //   store.dispatch('app/setLanguage', 'en-US')
  // }

  //  Deal with the case of possibly incorrect language code.
  console.log('before', to)
  var lang = to.params ? to.params.lang : null
  const isoLangList = [
    'en', 'en-au', 'en-bz', 'en-ca', 'en-cb', 'en-gb', 'en-ie', 'en-jm', 'en-nz', 'en-ph', 'en-tt', 'en-us', 'en-za', 'zn-zw',
    'zh-hk', 'zh-mo', 'zh-tw',
    'zh-cn', 'zh-sg'
  ]
  console.log('test', decodeURI(to.fullPath), decodeURIComponent(to.fullPath))
  if (decodeURI(to.fullPath) !== decodeURIComponent(to.fullPath)) {
    // URL is encoded
    console.log('decode URL')
    console.log('decodeURIComponent', decodeURIComponent(to.fullPath))
    next({ path: decodeURIComponent(to.fullPath).replaceAll('//', '/') })
  } else {
    //  Only need to redirect if current language ce is null, or invalid.
    console.log(`lang : ${lang}`)
    if (!lang || !isoLangList.includes(lang.toLowerCase())) {
      // not have language or not have in supporting list
      console.log('not have language or not have in supporting list', lang || 'null', to)
      const newTo = _.cloneDeep(to)
      newTo.params.lang = `${convertLangCode(navigator.language)}${to.path}`
      // newTo.path = `/:lang${to.path}`
      // newTo.fullPath = `/:lang${to.fullPath}`
      // newTo.redirect = { name: 'home' }
      // console.log(`newTo.params.lang : ${newTo.params.lang} , navigator.language : ${navigator.language}`)
      // console.log(to)
      // console.log(newTo)
      next(newTo)
    } else {
      console.log('have language')
      const supportedLangList = [
        'en-us', 'zh-hk', 'zh-cn'
      ]
      if (!supportedLangList.includes(lang.toLowerCase())) {
        // not the supported three language code
        console.log('not supported language')
        if (isoLangList.includes(lang.toLowerCase())) {
          // included in supporting list
          console.log('included in supporting list')
          const newTo = _.cloneDeep(to)
          newTo.params.lang = convertLangCode(lang)
          next(newTo)
        } else {
          // not included in supporting list
          console.log('not included in supporting list')
          const newTo = _.cloneDeep(to)
          newTo.params.lang = convertLangCode(navigator.language)
          next(newTo)
        }
      } else {
        console.log('supported language')
      }
    }
  }
  next()
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

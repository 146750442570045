import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

Vue.use(VueI18n)

function loadLocaleMessages() {
  // eslint-disable-next-line no-unused-vars
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    // const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    const lang = key.split('/')[1]
    messages[lang] = { ...messages[lang], ...locales(key) }
    // if (matched && matched.length > 1) {
    //   const locale = matched[1]
    //   messages[locale] = locales(key)
    // }
  })
  console.log(messages)
  return messages
}

export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  // eslint-disable-next-line no-unused-vars
  const locales = Object.keys(loadLocaleMessages())
  // eslint-disable-next-line no-unused-vars
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en-US'
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-US',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  silentTranslationWarn: true,
  messages: loadLocaleMessages()
})

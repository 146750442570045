import axios from 'axios'

const state = {
  title: '',
  data: {}
}

const mutations = {
  SET_EVENT_TITLE: (state, title) => {
    state.title = title
  },
  GET_EVENT_DATA: (state, data) => {
    state.data = data
  },
  RESET_EVENT: (state) => {
    state.title = ''
    state.data = {}
  }
}

const actions = {
  async getEventData({ commit, rootState }, title) {
    var basepath = window.location.origin
    var event = rootState.events.event_list.find(event => event.title === title)
    if (event) {
      var eventInfoUrl = event.eventInfo
      await axios.get(`${basepath}/assets/${eventInfoUrl}`)
        .then((res) => {
          commit('SET_EVENT_TITLE', title)
          commit('GET_EVENT_DATA', { ...event, ...res.data })
        })
        .catch(err => {
          console.log(err)
          commit('RESET_EVENT')
        })
    }
  },
  resetEventData({ commit }) {
    commit('RESET_EVENT')
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state
}


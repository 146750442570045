<template>
  <span>
    <!-- Go to Top -->
    <a
      class="js-go-to go-to position-fixed"
      href="#stickyBlockStartPoint"
      style="visibility: hidden;"
      data-hs-go-to-options="{
        &quot;targetSelector&quot; : &quot;#logoAndNav&quot;,
        &quot;offsetTop&quot;: 200,
        &quot;position&quot;: {
          &quot;init&quot;: {
            &quot;right&quot;: 30
          },
          &quot;show&quot;: {
            &quot;bottom&quot;: 30
          },
          &quot;hide&quot;: {
            &quot;bottom&quot;: -30
          }
        }
      }"
    >
      <i class="fas fa-angle-up" />
    </a>
  <!-- End Go to Top -->
  </span>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
export default {
  name: 'GoToTopButton',
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  }
}
</script>
<style lang="scss" scoped>
// @import '~slick-carousel/slick/slick.css';
</style>

<template>
  <header
    id="header"
    class="header header-box-shadow"
    data-hs-header-options="{
      &quot;fixMoment&quot;: 1000,
      &quot;fixEffect&quot;: &quot;slide&quot;
    }"
  >
    <!-- <div class="fabrix-banner">
        <p class="fabrix-banner-description">
          🎉 <span v-html="$t('Header.TopBar')" />
        </p>
      </div> -->
    <div class="header-section">
      <div id="logoAndNav" class="container">
        <nav class="js-mega-menu navbar navbar-expand-lg">
          <!-- Logo -->
          <router-link :to="{ path: `/${$route.params.lang}/`}" class="navbar-brand" aria-label="OpenAPIHub">
            <img src="@/assets/svg/logos/logo.svg" alt="OpenAPIHub">
          </router-link>
          <!-- End Logo -->

          <!-- Responsive Toggle Button -->
          <button id="navHideButton" v-b-toggle.navBar class="navbar-toggler btn btn-icon btn-sm rounded-circle" aria-label="Toggle navigation">
            <span class="navbar-toggler-default">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
              </svg>
            </span>
            <span class="navbar-toggler-toggled">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </span>
          </button>
          <!-- End Responsive Toggle Button -->

          <!-- Navigation -->
          <b-collapse id="navBar" class="navbar-nav-wrap-navbar collapse navbar-collapse">
            <div class="navbar-body header-abs-top-inner">
              <ul class="js-scroll-nav navbar-nav header-navbar-nav">
                <!-- <li
                  class="hs-has-sub-menu navbar-nav-item"
                  data-hs-mega-menu-item-options="{
                      &quot;desktop&quot;: {
                      &quot;position&quot;: &quot;left&quot;
                      }
                    }"
                >
                  <a id="productMegaMenu" class="hs-mega-menu-invoker nav-link nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="productSubMenu">{{ $t('Header.Product') }}</a>
                  <div id="productSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="productMegaMenu" style="padding: 0;">
                    <div class="row no-gutters">
                      <div class="col-12 col-lg-6 py-2 pb-lg-4">
                        <span class="d-block small font-weight-bold text-cap pt-3 pb-2 px-4">{{ $t('Header.For') }}</span>
                        <router-link :to="{ path: `/${$route.params.lang}/api-provider`}" class="dropdown-item navbar-promo-link my-0" @click.native="hideMenu('productMegaMenu')">
                          <div class="media align-items-center">
                            <img class="navbar-promo-icon max-w-6rem" src="@/assets/svg/icons/icon-38.svg">
                            <div class="media-body">
                              <span class="navbar-promo-title">
                                {{ $t('Header.Provider') }}
                              </span>
                              <small class="navbar-promo-text">{{ $t('Header.ProviderDescription') }}</small>
                            </div>
                          </div>
                        </router-link>
                        <router-link :to="{ path: `/${$route.params.lang}/api-developer`}" class="dropdown-item navbar-promo-link" @click.native="hideMenu('productMegaMenu')">
                          <div class="media align-items-center">
                            <img class="navbar-promo-icon max-w-6rem" src="@/assets/svg/icons/icon-48.svg">
                            <div class="media-body">
                              <span class="navbar-promo-title">
                                {{ $t('Header.Developer') }}
                              </span>
                              <small class="navbar-promo-text">{{ $t('Header.DeveloperDescription') }}</small>
                            </div>
                          </div>
                        </router-link>
                        <router-link :to="{ path: `/${$route.params.lang}/tech-startup`}" class="dropdown-item navbar-promo-link" @click.native="hideMenu('productMegaMenu')">
                          <div class="media align-items-center">
                            <img class="navbar-promo-icon max-w-6rem" src="@/assets/svg/icons/icon-23.svg">
                            <div class="media-body">
                              <span class="navbar-promo-title">
                                {{ $t('Header.Startup') }}
                              </span>
                              <small class="navbar-promo-text">{{ $t('Header.StartupDescription') }}</small>
                            </div>
                          </div>
                        </router-link>
                        <router-link :to="{ path: `/${$route.params.lang}/enterprise-api-hub`}" class="dropdown-item navbar-promo-link" @click.native="hideMenu('productMegaMenu')">
                          <div class="media align-items-center">
                            <img class="navbar-promo-icon max-w-6rem" src="@/assets/svg/icons/icon-13.svg">
                            <div class="media-body">
                              <span class="navbar-promo-title">
                                {{ $t('Header.Enterprise') }}
                              </span>
                              <small class="navbar-promo-text">{{ $t('Header.EnterpriseDescription') }}</small>
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <hr class="col-12 d-lg-none my-0">
                      <div class="col-12 col-lg-6 py-2 pb-lg-4 bg-lg-light">
                        <span class="d-block small font-weight-bold text-cap pt-3 pb-2 px-4">{{ $t('Header.Features') }}</span>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/api-portal`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.APIPortal') }}</router-link>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/api-management`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.APIManagement') }}</router-link>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/api-transformations`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.APITransformations') }}</router-link>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/api-monetization`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.APIMonetization') }}</router-link>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/apiops-cicd`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.CICD') }}</router-link>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/trust-certification`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.Trust') }}</router-link>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/api-hub-community`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.Community') }}</router-link>
                        <router-link id="" :to="{ path: `/${$route.params.lang}/themes`}" class="dropdown-item" @click.native="hideMenu('productMegaMenu')">{{ $t('Header.Themes') }} <span class="badge badge-soft-primary ml-1">{{ $t('Header.New') }}</span></router-link>
                      </div>
                    </div>
                  </div>
                </li> -->

                <li
                  class="hs-has-sub-menu navbar-nav-item"
                  data-hs-mega-menu-item-options="{
                      &quot;desktop&quot;: {
                      &quot;position&quot;: &quot;left&quot;
                      }
                    }"
                >
                  <a id="roleMegaMenu" class="hs-mega-menu-invoker nav-link nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="roleSubMenu">{{ $t('Footer.Nav1.Title') }}</a>
                  <div id="roleSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="roleMegaMenu" style="min-width: 230px;">
                    <router-link :to="{ path: `/${$route.params.lang}/api-developer`}" class="dropdown-item" @click.native="hideMenu('roleMegaMenu')">{{ $t('Header.Developer') }}</router-link>
                    <router-link :to="{ path: `/${$route.params.lang}/api-provider`}" class="dropdown-item" @click.native="hideMenu('roleMegaMenu')">{{ $t('Header.Provider') }}</router-link>
                  </div>
                </li>

                <li
                  class="hs-has-sub-menu navbar-nav-item"
                  data-hs-mega-menu-item-options="{
                      &quot;desktop&quot;: {
                      &quot;position&quot;: &quot;left&quot;
                      }
                    }"
                >
                  <a id="learnMegaMenu" class="hs-mega-menu-invoker nav-link nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="learnSubMenu">{{ $t('Header.Learn') }}</a>
                  <div id="learnSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="learnMegaMenu" style="min-width: 230px;">
                    <router-link id="ga-OW-CC01-10a" :to="{ path: `/${$route.params.lang}/academy`}" class="dropdown-item" @click.native="hideMenu('learnMegaMenu')">{{ $t('Header.Academy') }}</router-link>
                    <a id="ga-OW-CC01-11" href="https://blog.openapihub.com/" target="_blank" class="dropdown-item" @click.native="hideMenu('learnMegaMenu')">{{ $t('Header.Blog') }}</a>
                    <a id="" :href="$t('Header.Guide_URL')" target="_blank" class="dropdown-item" @click.native="hideMenu('learnMegaMenu')">{{ $t('Header.Guide') }}</a>
                    <router-link id="ga-OW-CC01-10b" :to="{ path: `/${$route.params.lang}/community-updates`}" class="dropdown-item" @click.native="hideMenu('learnMegaMenu')">{{ $t('Header.Events') }}</router-link>
                  </div>
                </li>

                <li
                  class="hs-has-sub-menu navbar-nav-item"
                  data-hs-mega-menu-item-options="{
                        &quot;desktop&quot;: {
                        &quot;position&quot;: &quot;left&quot;
                        }
                    }"
                >
                  <a id="aboutMegaMenu" class="hs-mega-menu-invoker nav-link nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="aboutSubMenu">{{ $t('Header.About') }}</a>
                  <div id="aboutSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="aboutMegaMenu" style="min-width: 230px;">
                    <router-link :to="{ path: `/${$route.params.lang}/about`}" class="dropdown-item" @click.native="hideMenu('aboutMegaMenu')">{{ $t('Header.About') }}</router-link>
                    <router-link :to="{ path: `/${$route.params.lang}/press`}" class="dropdown-item" @click.native="hideMenu('aboutMegaMenu')">{{ $t('Header.Press') }}</router-link>
                    <router-link :to="{ path: `/${$route.params.lang}/contact`}" class="dropdown-item" @click.native="hideMenu('aboutMegaMenu')">{{ $t('Header.Contact') }}</router-link>
                  </div>
                </li>

                <li class="navbar-nav-item mr-lg-3">
                  <router-link :to="{ path: `/${$route.params.lang}/pricing`}" class="nav-link">{{ $t('Header.Pricing') }}</router-link>
                </li>

                <!-- Language -->
                <!-- <li v-show="(mounted)" class="hs-has-sub-menu navbar-nav-item mr-lg-3">
                    <a id="languageMegaMenu" class="hs-mega-menu-invoker nav-link" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="learnSubMenu" :class="font_color === 'white' ? 'hrefColorWhite' : 'fontColorGray'"><i class="far fa-globe mr-1" />{{ selectedLang }} <i class="fas fa-angle-down ml-2 d-lg-none" /></a>
                    <div id="languageSubMenu" class="hs-sub-menu dropdown-menu dropdown-reverse" aria-labelledby="languageMegaMenu" style="min-width: 130px;" :style="(themeCode !== 'general') ? 'border-top:3px #FFFFFF solid;' : 'border-top:3px #57abf0 solid;'">
                      <div v-for="(item, index) in languageSubmenuItems" :key="index" @click="changeLanguage(item.lang_code); hideMenu('languageMegaMenu');">
                        <div :id="item.id ? item.id : null" class="dropdown-item cursor-pointer lang-dropdown-item" :class="themeCode !== 'general' ? 'hrefColorGray' : ''">{{ item.name }}</div>
                      </div>
                    </div>
                  </li> -->
                <!-- End Language  -->

                <li class="navbar-nav-item navbar-button pl-0 pl-lg-2 pt-2 pt-lg-0">
                  <a id="ga-OW-CC01-08" href="https://hub.openapihub.com/" class="btn btn-sm btn-outline-primary btn-block d-lg-inline-block" target="_blank">
                    {{ $t('Header.APIHub') }}
                  </a>
                </li>

                <li class="navbar-nav-item navbar-nav-last-item">
                  <a id="ga-OW-CC01-07" href="https://hub.openapihub.com/login" class="btn btn-sm btn-primary btn-block d-lg-inline-block" target="_blank">{{ $t('Header.PrimaryButton') }}</a>
                </li>

                <!-- <li
                    class="hs-has-sub-menu navbar-nav-item"
                    data-hs-mega-menu-item-options="{
                      &quot;desktop&quot;: {
                      &quot;position&quot;: &quot;left&quot;
                      }
                    }"
                  >
                    <a id="useCasesMegaMenu" class="hs-mega-menu-invoker nav-link nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="useCasesSubMenu">{{ $t('Header.UseCases') }}</a>
                    <div id="useCasesSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="useCasesMegaMenu" style="min-width: 230px;">
                      <router-link v-for="(category, index) in useCasesCategories" :key="index" :to="`/use-cases/${category.id}`" class="dropdown-item" @click.native="hideMenu('useCasesMegaMenu')">
                        {{ $t(`Header.${category.label}`) }}
                      </router-link>
                    </div>
                  </li> -->
                <!-- <li class="navbar-nav-item">
                    <router-link :to="{ path: '/', hash: '#mediaSection'}" class="nav-link header-nav-link" @click.native="scrollToId('mediaSection')">{{ $t('Header.Media') }}</router-link>
                  </li> -->
                <!-- <li class="navbar-nav-item navbar-nav-last-item">
                    <router-link
                      id="ga-OW-CC01-07"
                      :to="{ path: '/portal-generator'}"
                      class="btn btn-sm btn-primary d-lg-inline-block transition-3d-hover"
                      @click.native="hideMenu()"
                    >
                      {{ $t('Header.GenerateApiPortal') }}
                    </router-link>
                  </li> -->
              </ul>
            </div>
          </b-collapse>
          <!-- End Navigation -->
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
const $ = require('jquery')
import HSHeader from '@/assets/vendor/hs-header/src/js/hs-header'
import HSMegaMenu from '@/assets/vendor/hs-mega-menu/src/js/hs-mega-menu'
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
// import HSUnfold from '@/assets/vendor/hs-unfold/src/js/hs-unfold'
export default {
  name: 'Navbar',
  // data: function() {
  //   return {
  //     useCasesCategories: [
  //       {
  //         label: 'Catering',
  //         id: 'catering'
  //       },
  //       {
  //         label: 'Communication',
  //         id: 'communication'
  //       },
  //       {
  //         label: 'Education',
  //         id: 'education'
  //       },
  //       {
  //         label: 'ESport',
  //         id: 'e-sport'
  //       },
  //       {
  //         label: 'FinancialServices',
  //         id: 'financial-services'
  //       },
  //       {
  //         label: 'Healthcare',
  //         id: 'healthcare'
  //       },
  //       {
  //         label: 'Insurance',
  //         id: 'insurance'
  //       },
  //       {
  //         label: 'Tourism',
  //         id: 'tourism'
  //       }
  //     ],
  //     languageSubmenuItems: [
  //       { name: 'EN', lang_code: 'en-us' },
  //       { name: '繁', lang_code: 'zh-hk' },
  //       { name: '简', lang_code: 'zh-cn' }
  //     ]
  //   }
  // },
  // computed: {
  //   selectedLang() {
  //     return this.languageSubmenuItems.find(item => item.lang_code === this.usingLangCode).name
  //   },
  //   usingLangCode() {
  //     switch (this.$route.params.lang) {
  //       case 'zh-hk':
  //       case 'zh-mo':
  //       case 'zh-tw':
  //         return 'zh-hk'

  //       case 'zh-cn':
  //       case 'zh-sg':
  //         return 'zh-cn'

  //       default:
  //         return 'en-us'
  //     }
  //   }
  // },
  mounted() {
    new HSHeader($('#header')).init()
    new HSMegaMenu($('.js-mega-menu')).init()
    new HSGoTo($('.js-go-to')).init()
    // new HSUnfold($('.js-hs-unfold-invoker')).init()
    this.mounted = true
  },
  methods: {
    hideMenu(menuLabel = '') {
      const isMobile = $('#navHideButton').css('display') === 'flex'
      if (menuLabel !== '') {
        $("[aria-labelledby='" + menuLabel + "']").css('display', 'none')
      }
      if (isMobile) {
        $('#navHideButton').click()
      }
    },
    // changeLanguage(lang) {
    //   this.$router.push({
    //     ...this.$route,
    //     params: {
    //       lang: lang
    //     }
    //   })
    // },
    scrollToId(id) {
      this.hideMenu()
      document.getElementById(id) ? document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' }) : null
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:map";
@import '../../styles/variables.scss';
@import url("../../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.css");
#header {
  width: 100vw;
}
.dropdown-reverse {
  left: auto !important;
  right: 0px !important;
}

.force-hide {
  display: none !important;
}
.navbar-button {
  padding-left: 1rem;
}

@media (min-width: 992px) {
  .bg-lg-light {
      background-color: #f8fbff !important;
  }

  #productSubMenu {
      min-width: 520px;
  }
}

.navbar-promo-title {
  font-size: 0.875rem;
}

.navbar-promo-text {
  font-size: 0.8rem;
  color: #999999;
}

.fabrix-banner {
    background: linear-gradient(90deg,#57abf0,#54c9b9);
    padding: 8px;
}

.fabrix-banner .fabrix-banner-description {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
}
</style>

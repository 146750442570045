<template>
  <div>
    <footer>
      <div class="container space-2 text-center">
        <p class="small text-muted">Copyrights © {{ new Date().getFullYear() }} All Rights Reserved by FiberAPI Technologies Limited.</p>
        <span v-for="(item, index) in links" :key="index" class="small text-muted link-item">
          <a :href="item.link" target="_blank">{{ item.text }}</a>
          <span v-if="index !== links.length-1" class="link-separator">/</span>
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'QRCodeFooter',
  data() {
    return {
      links: [
        { text: 'Terms of Use', link: 'https://fiberapi.com/zh/terms-of-use/' },
        { text: 'Privacy Policy', link: 'https://fiberapi.com/zh/privacy-policy/' },
        { text: 'Contact Us', link: 'https://fiberapi.com/zh/contact/' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}
a {
  color: #29B8AC;
}
.link-item {
  display: inline-block;
}
.link-separator {
  margin: 0px 8px;
}
</style>

<template>
  <div>
    <footer class="bg-navy position-relative">
      <div class="container position-relative z-index-2">
        <!-- ========== Info ========== -->
        <div class="space-2">
          <div class="row justify-content-lg-between">
            <div class="col-lg-3 ml-lg-auto mb-5 mb-lg-0">
              <div class="mb-4">
                <router-link :to="{ path: `/${$route.params.lang}/`}">
                  <img class="brand" src="@/assets/svg/logos/logo-white.svg">
                </router-link>
              </div>
            </div>

            <div class="col-6 col-md col-lg mb-5 mb-md-0">
              <h5 class="text-white font-weight-bold">{{ $t('Footer.Nav1.Title') }}</h5>
              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/api-developer`}">{{ $t('Footer.Nav1.Item2') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/api-provider`}">{{ $t('Footer.Nav1.Item1') }}</router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/tech-startup`}">{{ $t('Footer.Nav1.Item4') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/enterprise-api-hub`}">{{ $t('Footer.Nav1.Item3') }}</router-link>
                </li> -->
              </ul>
            </div>

            <!-- <div class="col-6 col-md col-lg mb-5 mb-md-0">
              <h5 class="text-white font-weight-bold">{{ $t('Footer.Nav2.Title') }}</h5>
              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/api-portal`}">{{ $t('Footer.Nav2.Item1') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/api-management`}">{{ $t('Footer.Nav2.Item2') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/api-transformations`}">{{ $t('Footer.Nav2.Item3') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/api-monetization`}">{{ $t('Footer.Nav2.Item4') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/apiops-cicd`}">{{ $t('Footer.Nav2.Item5') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/trust-certification`}">{{ $t('Footer.Nav2.Item7') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/api-hub-community`}">{{ $t('Footer.Nav2.Item8') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/themes`}">{{ $t('Footer.Nav2.Item9') }}</router-link>
                </li>
              </ul>
            </div> -->

            <div class="col-6 col-md col-lg mb-5 mb-md-0">
              <h5 class="text-white font-weight-bold">{{ $t('Footer.Nav3.Title') }}</h5>
              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <a class="nav-link" href="https://hub.openapihub.com/" target="_blank">{{ $t('Footer.Nav3.Item1') }}</a>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/pricing`}">{{ $t('Footer.Nav3.Item3') }}</router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/book-demo`}">{{ $t('Footer.Nav3.Item2') }}</router-link>
                </li> -->
              </ul>
            </div>

            <div class="col-6 col-md col-lg mb-5 mb-md-0">
              <h5 class="text-white font-weight-bold">{{ $t('Footer.Nav4.Title') }}</h5>
              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/academy`}">{{ $t('Footer.Nav4.Item1') }}</router-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://blog.benovelty.com/" target="_blank">{{ $t('Footer.Nav4.Item2') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :href="$t('Header.Guide_URL')" target="_blank">{{ $t('Footer.Nav4.Item3') }}</a>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/use-cases`}">{{ $t('Footer.Nav4.Item4') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/community-updates`}">{{ $t('Footer.Nav4.Item5') }}</router-link>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md col-lg mb-5 mb-md-0">
              <h5 class="text-white font-weight-bold">{{ $t('Footer.Nav5.Title') }}</h5>
              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/about`}">{{ $t('Footer.Nav5.Item1') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/press`}">{{ $t('Footer.Nav5.Item2') }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ path: `/${$route.params.lang}/contact`}">{{ $t('Footer.Nav5.Item3') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- ========== End Info ========== -->

        <hr class="opacity-xs my-0">

        <!-- ========== Terms & Copyright ========== -->
        <div class="space-2">
          <div class="row align-items-md-center mb-7">
            <div class="col-md-6 mb-4 mb-md-0 z-index-999">
              <ul class="nav nav-sm nav-white nav-x-sm align-items-center">
                <li class="nav-item">
                  <a class="nav-link" href="https://www.benovelty.com/openapihub/terms-of-use/" target="_blank">{{ $t('Footer.TermsOfUse') }}</a>
                </li>
                <li class="nav-item opacity mx-3 text-white">/</li>
                <li class="nav-item">
                  <a class="nav-link" href="https://www.benovelty.com/openapihub/privacy-policy" target="_blank">{{ $t('Footer.PrivacyPolicy') }}</a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 text-md-right z-index-999">
              <ul class="list-inline mb-0">
                <li v-for="(media, index) in socialMedia" :key="index" class="list-inline-item">
                  <a class="btn btn-xs btn-icon btn-soft-light" :href="media.link" target="_blank">
                    <i class="fab" :class="media.icon" />
                  </a>
                </li>
                <!-- Language -->
                <li v-show="(mounted)" class="list-inline-item">
                  <div class="hs-unfold">
                    <a
                      class="js-hs-unfold-invoker dropdown-toggle btn btn-xs btn-soft-light"
                      href="javascript:;"
                      data-hs-unfold-options="{
                        &quot;target&quot;: &quot;#languageMenu&quot;,
                        &quot;type&quot;: &quot;css-animation&quot;,
                        &quot;animationIn&quot;: &quot;slideInDown&quot;,
                        &quot;hideOnScroll&quot;: &quot;true&quot;
                      }"
                    >
                      <i class="far fa-globe mr-1" /> <span>{{ selectedLang }}</span><i class="fas fa-angle-down ml-2" />
                    </a>
                    <div id="languageMenu" class="hs-unfold-content dropdown-menu dropdown-unfold dropdown-menu-bottom mb-2">
                      <a
                        v-for="(item, index) in languageMenuItems"
                        :id="item.id ? item.id : null"
                        :key="index"
                        class="dropdown-item"
                        :class="{ 'active': item.name === selectedLang }"
                        href="javascript:;"
                        @click="changeLanguage(item.lang_code);"
                      >{{ item.name }}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="text-md-center">
            <p class="text-white opacity small mb-2"><span v-html="$t('Footer.Tagline')" /></p>
            <p class="text-white opacity small">© {{ new Date().getFullYear() }} <span v-html="$t('Footer.Copyrights')" /></p>
          </div>
        </div>
      <!-- ========== End Terms & Copyright ========== -->
      </div>
    </footer>
  </div>

  <!-- <div>
    <footer class="gradient-y-sm-primary">
      <div class="container space-2 text-center">
        <div class="d-inline-flex align-items-center mb-3">
          <img class="brand" src="@/assets/svg/logos/logo.svg" alt="Logo">
        </div>
        <p class="font-size-1 mb-1" v-html="$t('Footer.Home')" />
        <div class="font-size-1 text-muted mb-2">
          <a class="text-navy link-underline" href="https://www.benovelty.com/terms-of-use" target="_blank">{{ $t('Footer.TermsOfUse') }}</a> /
          <a class="text-navy link-underline" href="https://www.benovelty.com/privacy-policy" target="_blank">{{ $t('Footer.PrivacyPolicy') }}</a>
        </div>
        <ul class="list-inline mb-0">
          <li v-for="(media, index) in socialMedia" :key="index" class="list-inline-item mb-2 mb-sm-0">
            <a class="btn btn-icon btn-sm btn-ghost-secondary" :href="media.link" target="_blank">
              <i class="fab" :class="media.icon" />
            </a>
          </li>
        </ul>
        <p class="small text-muted">© {{ new Date().getFullYear() }} {{ $t('Footer.Copyrights') }}</p>
      </div>
    </footer>
  </div> -->
</template>

<script>
const $ = require('jquery')
import HSUnfold from '@/assets/vendor/hs-unfold/src/js/hs-unfold'
export default {
  name: 'Footer',
  data() {
    return {
      socialMedia: [
        { icon: 'fa-facebook-f', link: 'https://www.facebook.com/OpenAPIHub' },
        { icon: 'fa-linkedin', link: 'https://www.linkedin.com/showcase/openapihub' },
        { icon: 'fa-twitter', link: 'https://twitter.com/openapihub' },
        { icon: 'fa-youtube', link: 'https://www.youtube.com/channel/UCNiuN1CeaqVgrtA30q0ZYmw' }
      ],
      languageMenuItems: [
        { name: 'English', lang_code: 'en-us' },
        { name: '繁體中文', lang_code: 'zh-hk' },
        { name: '简体中文', lang_code: 'zh-cn' }
      ]
    }
  },
  computed: {
    selectedLang() {
      return this.languageMenuItems.find(item => item.lang_code === this.usingLangCode).name
    },
    usingLangCode() {
      switch (this.$route.params.lang) {
        case 'zh-hk':
        case 'zh-mo':
        case 'zh-tw':
          return 'zh-hk'

        case 'zh-cn':
        case 'zh-sg':
          return 'zh-cn'

        default:
          return 'en-us'
      }
    }
  },
  mounted() {
    new HSUnfold($('.js-hs-unfold-invoker')).init()
    this.mounted = true
  },
  methods: {
    changeLanguage(lang) {
      this.$router.push({
        ...this.$route,
        params: {
          lang: lang
        }
      })
    }
  }
}
</script>

import axios from 'axios'

const state = {
  event_list: []
}

const mutations = {
  GET_EVENT_LIST: (state, data) => {
    state.event_list = data
  }
}

const actions = {
  async getEventList({ commit }) {
    var basepath = window.location.origin
    await axios.get(`${basepath}/assets/events/event-data/events.json`)
      .then((res) => {
        console.log('event-list', res.data.event_list)
        commit('GET_EVENT_LIST', res.data.event_list)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state
}
